import React, { useState } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/SEO'

const WorkPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(slug: {eq: "work"}) {
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        allWpPost {
          edges {
            node {
              projectImages {
                image1 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image2 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image3 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image4 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image5 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image6 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image7 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image8 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image9 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
                image10 {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }        
      }
    `
  )

  const [projectIndex, setProjectIndex] = useState(0)

  const handleNavProject = (direction) => {
    const newProjectIndex = direction === 'prev' ? projectIndex - 1 : projectIndex + 1
    setProjectIndex(newProjectIndex)
  }

  const content = data.wpPage.content
  const imageData = data.allWpPost.edges[projectIndex].node.featuredImage?.node?.localFile.childImageSharp.fluid || data.wpPage.featuredImage.node.localFile.childImageSharp.fluid

  const images = []
  for (let i = 0; i < 10; i++) {
    const image = data.allWpPost.edges[projectIndex].node.projectImages[`image${i}`]?.localFile.childImageSharp.fixed.src
    image && images.push(<img key={i} src={image} alt='Project' />)
  }

  return (
    <>
    <SEO title='Work' />
      <Layout {...{ content, imageData }}>
        <div className='project-container'>
          {projectIndex > 0 && (
            <button className='btn project-nav prev' onClick={() => handleNavProject('prev')}>
              &lt; prev project
            </button>
          )}
          <div className='project-images'>
            {images}
          </div>
          {projectIndex < data.allWpPost.edges.length - 1 && (
            <button className='btn project-nav next' onClick={() => handleNavProject('next')}>
              next project &gt;
            </button>
          )}
        </div>
      </Layout>
    </>
  )
}

export default WorkPage
